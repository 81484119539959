import { useDispatch, useSelector } from 'react-redux';
import history from '@history';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-scroll';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import withReducer from 'app/store/withReducer';
import 'react-phone-input-2/lib/style.css';
import CircularProgress from '@mui/material/CircularProgress';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './Header';
import Footer from './Footer';
import { getDecryptData } from '../common/common';
import CookieBar from './CookieBar';
import BrandLogo from './BrandLogo';
import Testimonial from './Testimonial';
import reducer from './store';
import { getPackages, resetLanding, getPlansAndFeaturesData } from './store/landingSlice';
import LandingQuery from '../query/landingDetail';

function Landing() {
  const dispatch = useDispatch();

  const getPackageResponse = useSelector(
    ({ landingReducer }) => landingReducer?.landing?.getPackagesData?.data?.getPackages?.data || []
  );

  const getPlansAndFeature = useSelector(
    ({ landingReducer }) =>
      landingReducer?.landing?.getPlansAndFeaturesDataValue?.data?.getPlansAndFeaturesData?.data ||
      []
  );

  const [loading, setLoading] = useState(true);
  const [activeInterval, setActiveInterval] = useState('monthActive');
  const ipBasedData = localStorage.getItem('ipBasedData')
    ? JSON.parse(getDecryptData(localStorage.getItem('ipBasedData')))
    : '';
  const [buttonRedirectLink, setButtonRedirectLink] = useState();

  let prevItem = null;

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      const payload = {
        query: LandingQuery.getPackages,
        variables: {
          countryId: ipBasedData?.countryId,
          packageInterval: `${activeInterval === 'monthActive' ? 'month' : 'year'}`,
        },
      };
      await dispatch(getPackages(payload));
      setLoading(false);
    };
    if (ipBasedData?.countryId) {
      fetchData();
      isMobile();
    }

    return () => {
      unmounted = true;
      dispatch(resetLanding());
    };
  }, [dispatch, ipBasedData?.countryId, activeInterval]);

  useEffect(() => {
    let unmounted = false;
    const getPlansAndFeatures = async () => {
      const payload = {
        query: LandingQuery.getPlansAndFeaturesData,
      };
      await dispatch(getPlansAndFeaturesData(payload));
    };
    getPlansAndFeatures();
    return () => {
      unmounted = true;
      dispatch(resetLanding());
    };
  }, [dispatch]);

  function isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (regex.test(navigator.userAgent)) {
      const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
      if (navigator.userAgent.match(/iPhone/i) || isSafari) {
        setButtonRedirectLink('https://itunes.apple.com/app/quick-reviews/id6449814972');
      } else {
        setButtonRedirectLink('https://play.google.com/store/apps/details?id=reviewApp.dev');
      }
    } else {
      setButtonRedirectLink('https://app.quickreviews.click/sign-up');
    }
  }

  function redirectAppsLink(plan) {
    if (plan?.packageTypeId) {
      history.push(`${process.env.REACT_APP_PORTAL_SIGNUP}/${plan?.packageTypeId}`);
    } else {
      history.push(buttonRedirectLink);
    }
  }
  function numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3
      ? `${x
          .toString()
          .substring(0, x.toString().split('.')[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${x
          .toString()
          .substring(x.toString().split('.')[0].length - 3)}`
      : x.toString();
  }

  return (
    <div style={{ backgroundColor: '#FAFBFC' }}>
      <Header />
      <div
        className="top-banner bg-darkgreen relative md:block overflow-hidden"
        style={{
          backgroundPosition: 'center',
          backgroundSize: '100% 100%',
          backgroundImage: `url('/assets/images/landing/stocklybannerimage.png')`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className="px-16 md:px-112 md:py-96 sm:py-20 sm:pt-20 pt-0 md:py-48 mx-auto"
          name="Home"
        >
          <div className="row flex items-center">
            <div className="md:w-3/5 flex">
              <div>
                <span className="block w-1 bg-white h-1/2 mx-auto hidden md:block" />
                <div>
                  <ul className="md:w-40 absolute bottom-48 md:-ml-20 slider-social">
                    <li className="inline-block md:block my-10 mx-5 md:mx-0">
                      <a
                        href="https://www.instagram.com/quickreviewsofficial"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <img
                          src="/assets/images/icon/instagram.svg"
                          alt="bad"
                          className="mx-auto w-auto h-auto"
                        />
                      </a>
                    </li>
                    <li className="inline-block md:block my-10 mx-5 md:mx-0">
                      <a
                        href="https://www.facebook.com/quickreviewsofficial"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <img
                          src="/assets/images/icon/facebook.svg"
                          alt="..."
                          className="mx-auto w-auto h-auto"
                        />
                      </a>
                    </li>
                    <li className="inline-block md:block my-10 mx-5 md:mx-0">
                      <a
                        href="https://www.youtube.com/@QuickReviewsofficial"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <img
                          src="/assets/images/icon/youtube.svg"
                          alt="..."
                          className="mx-auto w-auto h-auto"
                        />
                      </a>
                    </li>
                    <li className="inline-block md:block my-10 mr-10 md:mr-0  mx-5 md:mx-0">
                      <a
                        href="https://www.linkedin.com/company/quick-reviews/?viewAsMember=true"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <img
                          src="/assets/images/icon/linkedin.svg"
                          alt="bad"
                          className="mx-auto w-auto h-auto"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:pl-96">
                <h1
                  className="2xl:text-8xl text-36 font-medium text-white mb-20 md:mb-92 top-font"
                  data-aos="fade-right"
                  style={{ maxWidth: '1000px' }}
                >
                  Manage Your Online Reputation
                </h1>
                <div className="main-button" data-aos="fade-right">
                  <a
                    role="button"
                    target="_blank"
                    className="whitespace-nowrap px-14 sm:px-36 py-10 text-14 sm:text-20 rounded-3xl border sm:h-52 hover:bg-white hover:text-darkgreen text-white inline-block font-semibold"
                    href="https://itunes.apple.com/app/id6449814972"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/images/icon/apple-logo.svg"
                      alt="..."
                      className="inline-block align-bottom mr-10 w-20 sm:w-auto"
                    />
                    App Store
                  </a>
                  <a
                    role="button"
                    target="_blank"
                    className="whitespace-nowrap px-14 sm:px-36 py-10 text-14 sm:text-20 rounded-3xl border sm:h-52 ml-20 sm:ml-40 hover:bg-white hover:text-darkgreen text-white inline-block font-semibold"
                    href="https://play.google.com/store/apps/details?id=reviewApp.dev"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/images/icon/playstore-logo.svg"
                      alt="..."
                      className="inline-block align-bottom mr-10 w-20 sm:w-auto"
                    />
                    Play Store
                  </a>
                </div>
                <p
                  className="text-14 md:text-18 mt-20 md:mt-40 mb-20 md:mb-52 md:w-full md:w-3/5 text-white leading-6 md:leading-8"
                  data-aos="fade-right"
                  style={{ maxWidth: '590px' }}
                >
                  Quick Reviews empowers businesses to enhance their online reputation and attract
                  customers. Our app simplifies reviews, offers insights and provides tools for
                  growth. Explore Quick Reviews to take control of your online presence.
                </p>
              </div>
            </div>
            <div className="md:w-2/5 hidden md:inline-block overflow-hidden slider-img">
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer1"
              />
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer1 a"
              />
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer1 b"
              />
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer1 c"
              />
              <img
                src="/assets/images/icon/slider/plane.png"
                alt="plane"
                className="plane"
                loading="lazy"
              />
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer1 d"
                loading="lazy"
              />
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer2 a"
              />
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer2 b"
              />
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer2 c"
              />
              <img
                src="/assets/images/icon/slider/slider-star.svg"
                alt="star-1"
                className="w-20 h-20 star layer2 d"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-darkgreen-100 py-40">
        <div className="container">
          <BrandLogo />
        </div>
      </div>
      <div className="custom-pedding overflow-hidden">
        <div className="create-bot-part md:mt-96 mt-24 container">
          <h2
            className="text-center text-18 md:text-4xl text-darkgreen font-bold mb-28 mt-20 md:mt-96 aos-init aos-animate"
            data-aos="fade-up"
          >
            Our Feature at a Glance
          </h2>
          <Typography
            className="text-14 md:text-18 text-center font-medium mx-auto mb-16 md:mb-48 leading-8 aos-init aos-animate"
            data-aos="fade-up"
            style={{ maxWidth: '1000px' }}
          >
            Take advantage of features such as QR code based reviews, Customer insights, Support for
            multiple business locations, and Review Management.
          </Typography>
          <div className="row md:flex items-center md:-mx-24">
            <div className="md:w-1/2 md:px-16 md:px-24 aos-init aos-animate" data-aos="fade-right">
              <div className="bg-grey-50 shadow rounded-md p-8 md:p-20 flex items-center mb-16 md:mb-48 group">
                <div className="w-72 relative">
                  <img
                    src="/assets/images/icon/empty_star.svg"
                    alt="bad"
                    className="mx-auto w-72 h-72"
                  />
                  <img
                    src="/assets/images/icon/after-hover.svg"
                    alt="bad"
                    className="mx-auto w-72 h-72 absolute top-0 opacity-0	group-hover:opacity-100"
                  />
                </div>
                <div className="w-4/5 ml-16">
                  <h3 className="text-18 md:text-24 font-medium mb-10 mt-0">Manage your reviews</h3>
                  <Typography className="text-14 md:text-18">
                    Effortlessly manage reviews from sites such as Google & Yelp with Quick Reviews.
                    Engage with your customers, track trends, and enhance your online reputation all
                    from one platform.
                  </Typography>
                </div>
              </div>
              <div className="bg-grey-50 shadow rounded-md p-8 md:p-20 mb-16 md:mb-0 flex items-center group">
                <div className="w-72 relative">
                  <img
                    src="/assets/images/icon/empty_star.svg"
                    alt="bad"
                    className="mx-auto w-72 h-72"
                  />
                  <img
                    src="/assets/images/icon/after-hover.svg"
                    alt="bad"
                    className="mx-auto w-72 h-72 absolute top-0 opacity-0	group-hover:opacity-100"
                  />
                </div>
                <div className="w-4/5 ml-16">
                  <h3 className="text-18 md:text-24 font-medium mb-10 mt-0">
                    QR Code based Reviews
                  </h3>
                  <Typography className="text-14 md:text-18">
                    Quick Reviews' QR code simplifies customer feedback. Customers can effortlessly
                    share their experiences and suggest areas of improvements.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 md:px-16 md:px-24 aos-init aos-animate" data-aos="fade-left">
              <div className="bg-grey-50 shadow rounded-md p-8 md:p-20 flex items-center mb-16 md:mb-48 group">
                <div className="w-72 relative">
                  <img
                    src="/assets/images/icon/empty_star.svg"
                    alt="bad"
                    className="mx-auto w-72 h-72"
                  />
                  <img
                    src="/assets/images/icon/after-hover.svg"
                    alt="bad"
                    className="mx-auto w-72 h-72 absolute top-0 opacity-0	group-hover:opacity-100"
                  />
                </div>
                <div className="w-4/5 ml-16">
                  <h3 className="text-18 md:text-24 font-medium mb-10 mt-0">Review Insights</h3>
                  <Typography className="text-14 md:text-18">
                    Gain actionable insights from your reviews that helps you focus on what your
                    customers need. Track key metrics, identify trends, and measure customer
                    satisfaction levels.
                  </Typography>
                </div>
              </div>
              <div className="bg-grey-50 shadow rounded-md p-8 md:p-20 flex items-center group">
                <div className="w-72 relative">
                  <img
                    src="/assets/images/icon/empty_star.svg"
                    alt="bad"
                    className="mx-auto w-72 h-72"
                  />
                  <img
                    src="/assets/images/icon/after-hover.svg"
                    alt="bad"
                    className="mx-auto w-72 h-72 absolute top-0 opacity-0	group-hover:opacity-100"
                  />
                </div>
                <div className="w-4/5 ml-16">
                  <h3 className="text-18 md:text-24 font-medium mb-10 mt-0">
                    Multiple Business Locations
                  </h3>
                  <Typography className="text-14 md:text-18">
                    Effortlessly manage multiple businesses with Quick Reviews. Our Platform allows
                    users to easily add and handle reviews for multiple business locations, all from
                    a single account.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-grey-50 md:py-96 md:mt-96 mt-24 py-24 feature-part" name="Features">
          <div className="create-bot-part md:mt-96 mt-24 container mx-auto">
            <h2
              className="text-center text-18 md:text-4xl text-darkgreen font-bold mb-16 md:mb-28 aos-init aos-animate"
              data-aos="fade-up"
            >
              Simple And Smooth Features
            </h2>
            <Typography
              className="text-14 md:text-18 text-center font-medium mx-16 md:mx-auto mb-16 md:mb-48 leading-8 aos-init aos-animate"
              data-aos="fade-up"
              style={{ maxWidth: '1000px' }}
            >
              Enjoy a hassle-free experience with quick review. our app is designed to provide a
              user-friendly interface and seamless functionality, making review management simple
              and smooth.
            </Typography>
            <div className="row md:flex md:-mx-24">
              <div className="md:w-2/6 md:px-24 px-0">
                <div className="flex mb-16 md:mb-48 aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/icon/feature-1.svg"
                      alt="bad"
                      className="mx-auto w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5 ml-16">
                    <h3 className="text-16 md:text-20 font-semibold mb-10 mt-0">
                      <a
                        href="/multiple-review-sites"
                        role="button"
                        target="_blank"
                        className="no-underline text-black hover:text-darkgreen"
                      >
                        Multiple Review Sites
                      </a>
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 font-medium">
                      Managing reviews on multiple platforms From Quick Reviews offers several
                      significant benefits for businesses.
                    </Typography>
                  </div>
                </div>
                <div className="flex mb-16 md:mb-48 aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/icon/feature-2.svg"
                      alt="bad"
                      className="mx-auto w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5 ml-16">
                    <h3 className="text-16 md:text-20 font-semibold mb-10 mt-0">
                      <a
                        href="/business-analytics"
                        role="button"
                        target="_blank"
                        className="no-underline text-black hover:text-darkgreen"
                      >
                        Business Analytics
                      </a>
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 font-medium">
                      The provision of deep insights into online reputation and the competitive
                      landscape by Quick Reviews empowers business owners with valuable information
                      to make informed decisions and drive improvements in various aspects of their
                      operations.
                    </Typography>
                  </div>
                </div>
                <div className="flex aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/icon/feature-3.svg"
                      alt="bad"
                      className="mx-auto w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5 ml-16">
                    <h3 className="text-16 md:text-20 font-semibold mb-10 mt-0">
                      <a
                        href="/social-media-engagement"
                        role="button"
                        target="_blank"
                        className="no-underline text-black hover:text-darkgreen"
                      >
                        Social Media Engagement
                      </a>
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 font-medium">
                      The feature of Quick Reviews automatically generating social media posts based
                      on customer reviews offers several benefits for businesses looking to maintain
                      an active and engaging presence on social networks.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="md:w-2/6 md:px-24 px-16 aos-init aos-animate" data-aos="fade-up">
                <img
                  src="/assets/images/icon/Simple_And_Smooth_Features.png"
                  alt="bad"
                  className="mx-auto my-24 md:my-0 w-auto h-auto"
                />
              </div>
              <div className="md:w-2/6 md:px-24 px-0">
                <div className="flex mb-16 md:mb-48 aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/icon/feature-4.svg"
                      alt="bad"
                      className="mx-auto w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5 ml-16">
                    <h3 className="text-16 md:text-20 font-semibold mb-10 mt-0">
                      <a
                        href="/support-for-teams"
                        role="button"
                        target="_blank"
                        className="no-underline text-black hover:text-darkgreen"
                      >
                        Support For Teams
                      </a>
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 font-medium">
                      The capability for users of Quick Reviews to invite others to their account
                      offers several benefits for businesses.
                    </Typography>
                  </div>
                </div>
                <div className="flex mb-16 md:mb-48 aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/icon/feature-5.svg"
                      alt="bad"
                      className="mx-auto w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5 ml-16">
                    <h3 className="text-16 md:text-20 font-semibold mb-10 mt-0">
                      <a
                        href="/more-effective-reviews"
                        role="button"
                        target="_blank"
                        className="no-underline text-black hover:text-darkgreen"
                      >
                        More Effective Reviews
                      </a>
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 font-medium">
                      The functionality of generating reviews based on various attributes rated by
                      customers with Quick Reviews offers several benefits for both businesses and
                      potential customers.
                    </Typography>
                  </div>
                </div>
                <div className="flex aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/icon/feature-6.svg"
                      alt="bad"
                      className="mx-auto w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5 ml-16">
                    <h3 className="text-16 md:text-20 font-semibold mb-10 mt-0">
                      <a
                        href="/service-recovery"
                        role="button"
                        target="_blank"
                        className="no-underline text-black hover:text-darkgreen"
                      >
                        Service Recovery
                      </a>
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 font-medium">
                      The feature of the Quick Reviews app that attempts to gather more information
                      from customers who had a negative experience and offers them the option to
                      speak to a staff member from the business represents a proactive approach to
                      addressing customer concerns and improving their future experiences.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filter md:mt-96 mt-24">
          <div className="container">
            <div className="row block md:flex items-center md:-mx-24 mdflex">
              <div className="md:w-3/5 px-24 aos-init aos-animate" data-aos="fade-left">
                <img
                  src="/assets/images/icon/Filter_And_Sort.png"
                  alt="bad"
                  className="mx-auto w-auto h-auto mb-24 md:mb-0"
                />
              </div>
              <div className="md:w-2/5 px-0 md:px-24 aos-init aos-animate" data-aos="fade-right">
                <h2 className="text-left text-18 md:text-4xl text-darkgreen font-bold mb-16 md:mb-28">
                  Filter And Sort
                </h2>
                <Typography
                  className="text-14 md:text-18 text-left font-medium mx-auto mb-16 md:mb-48 leading-8"
                  style={{ maxWidth: '1000px' }}
                >
                  Easily navigate through your reviews with Quick Reviews' filtering and sorting
                  options. Quickly find specific reviews, sort them by rating or date, and gain
                  valuable insights into customer sentiment and trends to inform your business
                  strategies.
                </Typography>
                <div className="">
                  <span
                    role="button"
                    // target="_blank"
                    aria-hidden="true"
                    className="whitespace-nowrap px-20 md:px-40 py-12 md:py-12 text-14 md:text-18 rounded-3xl border border-darkgreen md:h-52 hover:bg-darkgreen hover:text-white text-darkgreen inline-block font-semibold"
                    onClick={() => {
                      redirectAppsLink();
                    }}
                  >
                    Try it For Free
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="support md:mt-96 mt-24">
          <div className="container">
            <div className="row md:flex items-center md:-mx-24">
              <div className="md:w-2/5 px-0 md:px-24 aos-init aos-animate" data-aos="fade-left">
                <h2 className="text-left text-18 md:text-4xl text-darkgreen font-bold mb-16 md:mb-28">
                  Support Multiple Businesses
                </h2>
                <Typography
                  className="text-14 md:text-18 text-left font-medium mx-auto mb-16 md:mb-48 leading-8"
                  style={{ maxWidth: '1000px' }}
                >
                  With Quick Reviews, you can efficiently manage multiple businesses from a single
                  account. Seamlessly handle reviews, track performance, and engage with customers
                  across all your businesses, streamlining your review management process.
                </Typography>
                <div>
                  <span
                    role="button"
                    className="whitespace-nowrap px-20 md:px-40 py-12 md:py-12 text-14 md:text-18 rounded-3xl border border-darkgreen md:h-52 hover:bg-darkgreen hover:text-white text-darkgreen inline-block font-semibold"
                    onClick={() => {
                      redirectAppsLink();
                    }}
                    aria-hidden="true"
                  >
                    Try it For Free
                  </span>
                </div>
              </div>
              <div className="md:w-3/5 px-24 aos-init aos-animate" data-aos="fade-right">
                <img
                  src="/assets/images/icon/Multiple_Business_Support.png"
                  alt="bad"
                  className="mx-auto w-auto mt-16 md:mt-0 w-auto h-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="achievement text-white text-center md:py-96 md:mt-96 mt-24 py-24 hidden"
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url('/assets/images/icon/background_image.jpg')`,
          }}
        >
          <div className="container">
            <h2 className="text-center text-18 md:text-4xl text-white font-bold mb-16 md:mb-40">
              Celebrating Our Achievements
            </h2>
            <Typography
              className="text-14 md:text-18 text-center font-medium mx-auto md:mx-auto mb-16 md:mb-48 text-grey-300 leading-8"
              style={{ maxWidth: '1000px' }}
            >
              We take pride in helping businesses thrive. Our accomplishments include boosting
              online reputations, increasing customer satisfaction, and empowering businesses to
              reach new heights through effective review management.
            </Typography>
            <div className="inline-block md:flex justify-between count-unit">
              <div className="border border-white py-16 md:py-24 px-24 md:px-60 mb-16 md:mb-0">
                <img
                  src="/assets/images/icon/user.svg"
                  alt="bad"
                  className="mx-auto w-auto"
                  loading="lazy"
                />
                <h3 className="text-24 md:text-36 font-medium my-8">2256</h3>
                <Typography className="text-14 md:text-18 font-medium mt-0">
                  Lovely Users
                </Typography>
              </div>
              <div className="border border-white py-16 md:py-24 px-24 md:px-60 mb-16 md:mb-0">
                <img
                  src="/assets/images/icon/user.svg"
                  alt="bad"
                  className="mx-auto w-auto"
                  loading="lazy"
                />
                <h3 className="text-24 md:text-36 font-medium my-8">36545</h3>
                <Typography className="text-14 md:text-18 font-medium mt-0">
                  App Download
                </Typography>
              </div>
              <div className="border border-white py-16 md:py-24 px-24 md:px-60 mb-16 md:mb-0">
                <img
                  src="/assets/images/icon/user.svg"
                  alt="bad"
                  className="mx-auto w-auto"
                  loading="lazy"
                />
                <h3 className="text-24 md:text-36 font-medium my-8">2256</h3>
                <Typography className="text-14 md:text-18 font-medium mt-0">
                  Business Registered
                </Typography>
              </div>
              <div className="border border-white py-16 md:py-24 px-24 md:px-60 mb-16 md:mb-0">
                <img
                  src="/assets/images/icon/user.svg"
                  alt="bad"
                  className="mx-auto w-auto"
                  loading="lazy"
                />
                <h3 className="text-24 md:text-36 font-medium my-8">2256</h3>
                <Typography className="text-14 md:text-18 font-medium mt-0">
                  Happy Reviews
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="create-bot-part md:mt-96 mt-24 container mx-auto" name="Pricing">
          <h2
            className="text-center text-18 md:text-4xl text-darkgreen font-bold mb-16 md:mb-28 md:mt-96 mt-24 aos-init aos-animate"
            data-aos="fade-up"
          >
            Plans And Pricing
          </h2>
          <Typography
            className="text-14 md:text-18 text-center font-medium md:mx-auto mb-16 md:mb-48 leading-8 text-black md:mx-16 max-w-640 aos-init aos-animate"
            data-aos="fade-up"
          >
            Choose your plans according to your needs and business requirements. upgrade your plans
            as your business scales.
          </Typography>
          <div className="text-center mb-28">
            <span className="rounded-3xl border border-darkgreen inline-block">
              <span
                className={`${
                  activeInterval === 'monthActive'
                    ? 'bg-darkgreen text-white'
                    : 'bg-white text-darkgreen'
                } cursor-pointer text-18 font-medium py-8 inline-block rounded-3xl min-w-144 text-center`}
                aria-hidden="true"
                onClick={() => {
                  setActiveInterval('monthActive');
                }}
              >
                Monthly
              </span>
              <span
                className={`${
                  activeInterval === 'yearActive'
                    ? 'bg-darkgreen text-white'
                    : 'bg-white text-darkgreen'
                } cursor-pointer text-18 font-medium py-8 inline-block rounded-3xl min-w-144 text-center`}
                aria-hidden="true"
                onClick={() => {
                  setActiveInterval('yearActive');
                }}
              >
                Yearly
              </span>
            </span>
          </div>
          {getPackageResponse?.length > 0 ? (
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32 ">
              {getPackageResponse
                .filter((plan) => plan?.id !== 1)
                .sort((a, b) => {
                  if (a?.packageIndex < b?.packageIndex) {
                    return -1;
                  }
                  if (a?.packageIndex > b?.packageIndex) {
                    return 1;
                  }
                  return 0;
                })
                .map((plan) => {
                  const previousItem = prevItem;
                  prevItem = plan;
                  return (
                    <div
                      className="bg-white border-2 border-solid rounded-md p-28 inline-block w-full w-auto md:mb-0"
                      key={plan.id}
                    >
                      <div className="flex items-center">
                        <div className="h-full w-full">
                          <h2
                            className="text-darkgreen text-20 md:text-28 font-bold text-darkgreen mb-16 mt-0 aos-init aos-animate"
                            data-aos="fade-up"
                          >
                            {plan.name}
                          </h2>
                          <div className="flex min-h-92 aos-init aos-animate" data-aos="fade-up">
                            <div>
                              {plan?.amount && plan?.amount !== '0' ? (
                                <>
                                  <div className="mb-5 mt-0 ">
                                    <h3
                                      className={`inline-block my-0 ${
                                        plan?.offerAmount
                                          ? 'line-through text-black font-medium text-16 md:text-24'
                                          : 'text-darkgreen font-semibold text-18 md:text-28'
                                      }`}
                                    >
                                      <span>{ipBasedData?.countryId === 2 ? '₹' : '$'} </span>
                                      {ipBasedData?.countryId === 2
                                        ? numberWithCommas(plan.amount.toString())
                                        : plan.amount}{' '}
                                    </h3>

                                    {plan?.offerAmount && (
                                      <h3 className="my-0 text-18 md:text-24 font-semibold inline-block ml-10 text-darkgreen font-semibold text-18 md:text-28">
                                        <span>{ipBasedData?.currencySymbol}</span>
                                        {ipBasedData?.countryId === 2
                                          ? numberWithCommas(plan.offerAmount.toString())
                                          : plan.offerAmount}{' '}
                                      </h3>
                                    )}
                                  </div>
                                  <span className="block text-16 font-medium text-grey-700 mb-28">
                                    {activeInterval === 'monthActive'
                                      ? 'Per Month* (for a limited time)'
                                      : 'Per Year* (for a limited time)'}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>

                          <span
                            className="bg-darkgreen rounded-full text-18 font-medium text-white text-center py-9 block mb-40 aos-init aos-animate"
                            data-aos="fade-up"
                          >
                            <Link
                              to="apps"
                              role="button"
                              className="hover:text-darkgreen-100 sm:text-white text-white"
                              spy
                              smooth
                              duration={500}
                              activeClass="active"
                              onClick={() => {
                                redirectAppsLink(plan);
                              }}
                            >
                              Start A Free Trial
                            </Link>
                          </span>

                          <div className="text-16 font-medium leading-snug">
                            <h4 className="text-18 mb-20 font-bold text-center">Plan Features</h4>

                            <ul>
                              {Object.values(JSON.parse(plan.packageDetails))
                                .filter((item, idx) => idx < 18)
                                .map((feature, i) => (
                                  <li
                                    className="flex items-center justify-start mb-14 aos-init aos-animate"
                                    data-aos="fade-up"
                                    key={i}
                                  >
                                    {feature?.isAvailable === true ? (
                                      <img
                                        src="/assets/images/icon/right.png"
                                        alt=".."
                                        className="w-16 h-16 mr-8"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/images/icon/close.png"
                                        alt=".."
                                        className="w-16 h-16 mr-8"
                                      />
                                    )}
                                    <span className="text-16 font-semibold">{feature.name}</span>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="text-center">
              <CircularProgress size={96} className="text-darkgreen mx-auto" />
            </div>
          )}
        </div>
        <div className="container">
          <Typography
            className="text-20 text-black my-20 md:my-40 font-semibold aos-init aos-animate"
            data-aos="fade-up"
          >
            Compare Plans And Features
          </Typography>
          <div className="overflow-x-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="text-darkgreen font-[600] border-b-1 border-grey-300 text-16 md:text-24 p-5 md:p-16 aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    Features
                  </TableCell>
                  {getPlansAndFeature?.heading
                    ?.slice()
                    .sort((a, b) => a.index - b.index)
                    .map((item, i) => (
                      <TableCell
                        key={i}
                        className="text-darkgreen font-[600] text-center border-b-1 border-grey-300 text-16 md:text-24 p-5 md:p-16 aos-init aos-animate"
                        data-aos="fade-up"
                      >
                        {item.planName}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getPlansAndFeature?.featuresData
                  ?.slice()
                  .sort((a, b) => a.index - b.index)
                  .map((feature, i) => (
                    <TableRow key={i}>
                      <TableCell className="text-center flex gap-4 items-center text-black text-12 md:text-16 border-b-1 border-r-1 border-grey-300 font-bold p-5 md:p-16">
                        <Typography className="text-black font-semibold text-16" data-aos="flip-up">
                          {feature.featureName
                            .split(' ')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')}
                        </Typography>
                        <Tooltip className="hidden md:inline-block" title={feature?.description}>
                          <>
                            <img
                              className="w-14 h-14 md:w-20 md:h-20 m-auto ml-4"
                              title={feature?.description}
                              src="/assets/images/icon/info_icon.png"
                              alt="info"
                            />
                          </>
                        </Tooltip>
                      </TableCell>
                      {getPlansAndFeature?.heading
                        ?.slice()
                        .sort((a, b) => a.index - b.index)
                        .map((plan, j) => {
                          const valueForPlan = feature.value.find(
                            (value) => value.packageTypeId === plan.packageTypeId
                          );
                          return (
                            <TableCell
                              key={j}
                              className="text-center text-black text-12 md:text-16 border-b-1 border-grey-300 font-bold p-5 md:p-16"
                            >
                              <span className="aos-init aos-animate block" data-aos="flip-up">
                                {valueForPlan?.value ? (
                                  valueForPlan?.value === 'true' ? (
                                    <img
                                      className="w-20 h-20 m-auto"
                                      src="/assets/images/icon/right.png"
                                      alt="right"
                                    />
                                  ) : (
                                    valueForPlan?.value
                                  )
                                ) : (
                                  <span>-</span>
                                )}
                              </span>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
        <div>
          <div className="bg-darkgreen-100 text-center py-48 md:py-96 md:mt-96 mt-24">
            <div className="container">
              <h4
                className="text-18 md:text-40 font-semibold text-darkgreen mt-0 aos-init aos-animate"
                data-aos="fade-up"
              >
                What Our Customers Say
              </h4>
              <Typography
                className="font-medium text-18 max-w-640 mx-auto my-44 lg:max-w-screen-md aos-init aos-animate"
                data-aos="fade-up"
              >
                Discover why our clients love us! read firsthand experiences and testimonials from
                satisfied customers. real stories, real satisfaction – find out why we’re their top
                choice.
              </Typography>
              <Testimonial />
            </div>
          </div>
          <div className="container">
            <div
              className="download-part bg-darkgreen rounded-lg p-16 md:p-44 md:mt-96 mt-24 mx-0 md:mx-0"
              name="Resources"
            >
              <div className="row md:flex items-center md:-mx-24 mdflex">
                <div className="md:w-2/5 md:px-24">
                  {/* <div className="">
                    <a
                      role="button"
                      target="_blank"
                      className="whitespace-nowrap px-16 md:px-32 py-5 md:py-14 text-14 md:text-16 bg-darkgreen-200 rounded-lg border border-darkgreen-200 h-32 md:h-52 hover:bg-darkgreen hover:text-white text-white inline-block font-semibold"
                      href="/"
                      rel="noreferrer"
                    >
                      Start Today
                    </a>
                  </div> */}
                  <h2
                    className="text-left text-18 md:text-4xl text-white font-bold my-20 aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    Download The App
                  </h2>
                  <Typography
                    className="text-14 md:text-16 text-left font-medium mx-auto mb-16 md:mb-48 text-grey-300 leading-8 aos-init aos-animate"
                    data-aos="fade-up"
                    style={{ maxWidth: '1000px' }}
                  >
                    Experience the Power of Quick Reviews: Download Our App Today! Gain effortless
                    control over your online reviews, manage your reputation, and boost customer
                    satisfaction with our intuitive and feature-rich mobile application.
                  </Typography>
                  <div className="inline-block aos-init aos-animate" data-aos="fade-up">
                    <span className="items-center bg-white w-auto rounded-md">
                      <a
                        href="https://play.google.com/store/apps/details?id=reviewApp.dev"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/icons/playstore.svg"
                          alt="app store"
                          className="h-32 md:h-72 w-full
        px-3 py-3 bg-white rounded-lg"
                        />
                      </a>
                    </span>
                  </div>

                  <div
                    className="inline-block ml-5 md:ml-36 aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <span className="items-center bg-white w-auto rounded-md">
                      <a
                        href="https://itunes.apple.com/app/id6449814972"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/icons/appstore.svg"
                          alt="App Store"
                          className="h-32 md:h-72 w-full
        px-3 py-3 bg-white rounded-lg"
                        />
                      </a>
                    </span>
                  </div>
                </div>
                <div className="md:w-3/5 md:px-24">
                  <img
                    src="/assets/images/icon/quick_review_download.png"
                    alt="quick_review_download"
                    className="ml-auto relative -mb-16 w-auto h-auto md:-mb-44"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <CookieBar />
    </div>
  );
}
export default withReducer('landingReducer', reducer)(Landing);
