import { Typography } from '@mui/material';
import history from '@history';
import Button from '@mui/material/Button';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import InputLabel from '@mui/material/InputLabel';
import { Link } from 'react-scroll';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect, useMemo, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'notistack';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { publicIpv4 } from 'public-ip';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import withReducer from 'app/store/withReducer';
import CircularProgress from '@mui/material/CircularProgress';
import reducer from './store';
import { getEncryptedData, getDecryptData, handleApiRequest } from '../common/common';
import './Header.css';

import { saveQuickReviewContactUsData, getLocation } from './store/landingSlice';
import LandingQuery from '../query/landingDetail';

function Header() {
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
      .required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    message: yup.string().required('Message is required'),
    phoneNumber: yup.string().required('Phone number is required'),
  });
  const defaultValues = useMemo(
    () => ({
      email: '',
      name: '',
      message: '',
      phoneNumber: '',
    }),
    []
  );

  const saveQuickReviewContactUsDataStatus = useSelector(
    ({ landingReducer }) =>
      landingReducer?.landing?.saveQuickReviewContactUsDataDetail?.data
        ?.saveQuickReviewContactUsData || {}
  );

  const { control, reset, handleSubmit, formState, setError, clearErrors, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;
  const [state, setState] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [phoneNumberWithoutCode, setPhoneNumberWithoutCode] = useState('');
  const [phoneCountryCode, setPhoneCountryCode] = useState();
  const [phoneIsoCode, setPhoneIsoCode] = useState();
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipBasedData')
      ? JSON.parse(getDecryptData(localStorage.getItem('ipBasedData')))
      : null
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [countryCodeNumber, setCountryCodeNumber] = useState('');
  const [isoCode, setIsoCode] = useState('');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const location = useLocation();
  function redirectLink() {
    history.push('/');
  }

  const handleClose = useCallback(() => {
    setOpenContact(false);
    reset();
  }, [reset]);

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: LandingQuery.getLocation,
          variables: {
            data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`),
          },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
          }
          localStorage.setItem('ipBasedData', locationData);
        }
      };
      ipAddressData();
    }
  }, [dispatch, ipBasedData]);

  useEffect(() => {
    if (Object.keys(saveQuickReviewContactUsDataStatus).length > 0) {
      if (
        saveQuickReviewContactUsDataStatus.status === 400 ||
        saveQuickReviewContactUsDataStatus.status === 500
      ) {
        enqueueSnackbar(saveQuickReviewContactUsDataStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } else if (saveQuickReviewContactUsDataStatus.status === 200) {
        enqueueSnackbar(saveQuickReviewContactUsDataStatus.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        handleClose();
        reset(defaultValues);
      }
      closeSnackbar(3000);
      setButtonDisabled(false);
      setRefreshIcon(false);
    }
  }, [
    reset,
    dispatch,
    saveQuickReviewContactUsDataStatus,
    enqueueSnackbar,
    closeSnackbar,
    defaultValues,
    handleClose,
  ]);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const handleBlogRedirect = () => {
    history.push('/blog');
  };

  const handleLinkClick = () => {
    setOpenContact(true);
  };

  function handleRemove() {
    setOpenContact(false);
    reset(defaultValues);
  }

  function isValidEmail(value) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  }

  const handlePhoneChange = (value, country, event, formattedValue) => {
    const phoneNumberWithoutCodeValue = value.slice(country?.dialCode?.length);
    setPhoneNumber(phoneNumberWithoutCodeValue);
    setPhoneValue(value);
    setPhoneCountryCode(country.dialCode);
    setPhoneIsoCode(country.countryCode);
    setPhoneNumberWithoutCode(phoneNumberWithoutCodeValue);
    setCountryCodeNumber(`+${country.dialCode}`);
    setIsoCode(country.countryCode);
  };

  const onSubmit = async (data) => {
    if (!isValidEmail(data.email)) {
      setError('email', {
        type: 'manual',
        message: 'Invalid email format',
      });
      return;
    }

    setButtonDisabled(true);
    setRefreshIcon(true);
    const payload = {
      query: LandingQuery.saveQuickReviewContactUsData,
      variables: {
        name: data.name,
        email: data.email,
        message: data.message,
        phoneNumber: parseInt(phoneNumberWithoutCode, 10),
        countryCode: countryCodeNumber,
        isoCode: isoCode.toUpperCase(),
      },
    };
    const result = await handleApiRequest(payload);
    setButtonDisabled(false);
    setRefreshIcon(false);
    if (result?.saveQuickReviewContactUsData?.status === 200) {
      reset();
      enqueueSnackbar(result?.saveQuickReviewContactUsData?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.saveQuickReviewContactUsData?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    reset(defaultValues);
  };

  return (
    <div className="sticky top-0 left-0 right-0 z-10 bg-darkgreen">
      <div className="px-16 lg:px-112">
        <div className="top-header flex justify-between items-center sm:py-24 py-12">
          <div className="">
            <Typography
              onClick={() => {
                redirectLink();
              }}
            >
              <img
                className="react-logo cursor-pointer custom-filter"
                width={150}
                height={100}
                src="/assets/images/logo/logo.png"
                alt="stockly"
              />
            </Typography>
          </div>
          <div className="sm:text-16 font-medium">
            <Button
              onClick={toggleDrawer(true)}
              className="text-white p-0 min-w-0 sm:hidden"
              aria-label="Toggle Drawer"
            >
              <FormatAlignRightIcon />
            </Button>
            <SwipeableDrawer
              open={state}
              className="custom-drawer sm:hidden"
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <ul>
                <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                  <Link
                    to="Home"
                    role="button"
                    className="hover:text-darkgreen-100 sm:text-white text-black"
                    spy
                    smooth
                    duration={500}
                    activeClass="active"
                  >
                    Home
                  </Link>
                </li>
                {location.pathname === '/' && (
                  <>
                    <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                      <Link
                        to="Features"
                        role="button"
                        className="hover:text-darkgreen-100 sm:text-white text-black"
                        spy
                        smooth
                        duration={500}
                        activeClass="active"
                      >
                        Features
                      </Link>
                    </li>
                    <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                      <Link
                        to="Pricing"
                        role="button"
                        className="hover:text-darkgreen-100 sm:text-white text-black"
                        spy
                        smooth
                        duration={500}
                        activeClass="active"
                      >
                        Pricing
                      </Link>
                    </li>
                    <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                      <Link
                        to="Resources"
                        role="button"
                        className="hover:text-darkgreen-100 sm:text-white text-black"
                        spy
                        smooth
                        duration={500}
                        activeClass="active"
                      >
                        Resources
                      </Link>
                    </li>
                  </>
                )}
                <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                  <Link
                    to="blog"
                    role="button"
                    className="hover:text-darkgreen-100 sm:text-white text-black"
                    onClick={() => handleBlogRedirect()}
                    // spy
                    // smooth
                    // duration={500}
                    // activeClass="active"
                  >
                    Blog
                  </Link>
                </li>
                <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16 ">
                  <Link
                    to="ContactUs"
                    role="button"
                    className="hover:text-darkgreen-100 sm:text-white text-black"
                    spy
                    smooth
                    duration={500}
                    activeClass="active"
                    onClick={handleLinkClick}
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                  <a
                    href={process.env.REACT_APP_PORTAL}
                    role="button"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                    className="hover:text-darkgreen-100 sm:text-white text-black"
                  >
                    Login
                  </a>
                </li>
              </ul>
            </SwipeableDrawer>
            <ul className="hidden sm:block">
              <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                <Link
                  to="Home"
                  role="button"
                  className="hover:text-darkgreen-100 sm:text-white text-black"
                  spy
                  smooth
                  onClick={() => history.push('/')}
                  duration={500}
                  activeClass="active"
                >
                  Home
                </Link>
              </li>
              {location.pathname === '/' && (
                <>
                  <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                    <Link
                      to="Features"
                      role="button"
                      className="hover:text-darkgreen-100 sm:text-white text-black"
                      spy
                      smooth
                      duration={500}
                      activeClass="active"
                    >
                      Features
                    </Link>
                  </li>
                  <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                    <Link
                      to="Pricing"
                      role="button"
                      className="hover:text-darkgreen-100 sm:text-white text-black"
                      spy
                      smooth
                      duration={500}
                      activeClass="active"
                    >
                      Pricing
                    </Link>
                  </li>
                  <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                    <Link
                      to="Resources"
                      role="button"
                      className="hover:text-darkgreen-100 sm:text-white text-black"
                      spy
                      smooth
                      duration={500}
                      activeClass="active"
                    >
                      Resources
                    </Link>
                  </li>
                </>
              )}
              <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                <Link
                  to="blog"
                  role="button"
                  className="hover:text-darkgreen-100 sm:text-white text-black"
                  onClick={() => handleBlogRedirect()}
                  // spy
                  // smooth
                  // duration={500}
                  // activeClass="active"
                >
                  Blog
                </Link>
              </li>
              <>
                <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16 ">
                  <Link
                    to="ContactUs"
                    role="button"
                    className="hover:text-darkgreen-100 sm:text-white text-black"
                    spy
                    smooth
                    duration={500}
                    activeClass="active"
                    onClick={handleLinkClick}
                  >
                    Contact Us
                  </Link>
                </li>
                {openContact && (
                  <Dialog
                    classes={{
                      paper: 'm-4 p-4 md:p-12 lg:p-24 contact-dialog rounded-md bg-grey-50',
                    }}
                    open={openContact}
                    onClose={() => {
                      handleClose();
                    }}
                    fullWidth
                    maxWidth="md"
                  >
                    <DialogContent>
                      <div className="grid grid-cols-12 gap-4 p-8">
                        <div className="col-span-12 md:col-span-6">
                          <DialogTitle className="text-darkgreen font-semibold text-3xl md:text-4xl lg:text-5xl mb-2 p-2">
                            <Icon
                              onClick={handleRemove}
                              className="text-32 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                            >
                              cancel
                            </Icon>
                            Contact Us
                          </DialogTitle>
                          <DialogContentText className="text-black font-medium md:text-18 lg:text-18 mb-28">
                            Get in touch and let us know how we can assist you. We would be
                            delighted to hear from you. Here is how you can reach us.
                          </DialogContentText>
                          <div className="flex mb-56">
                            <span className="bg-darkgreen rounded-md block w-48 h-48 text-white text-center pt-12 mr-20">
                              <Icon>phone_in_talk</Icon>
                            </span>
                            <span>
                              <h3 className="text-20 font-medium">Mobile Number</h3>
                              <h4 className="text-16 font-medium text-grey-600">+91 80875 69265</h4>
                            </span>
                          </div>
                          <div className="flex mb-56">
                            <span className="bg-darkgreen rounded-md block w-48 h-48 text-white text-center pt-12 mr-20">
                              <Icon>phone_in_talk</Icon>
                            </span>
                            <span>
                              <h3 className="text-20 font-medium">Mobile Number</h3>
                              <h4 className="text-16 font-medium text-grey-600">
                                +1 (408) 430-3590
                              </h4>
                            </span>
                          </div>

                          <div className="flex mb-56">
                            <span className="bg-darkgreen rounded-md block w-48 h-48 text-white text-center pt-12 mr-20">
                              <Icon>mail</Icon>
                            </span>
                            <span>
                              <h3 className="text-20 font-medium">Email Address</h3>
                              <h4 className="text-16 font-medium text-grey-600">
                                info@quickreviews.click
                              </h4>
                            </span>
                          </div>

                          <div className="flex mb-56">
                            <span className="bg-darkgreen rounded-md block w-48 h-48 text-white text-center pt-12 mr-20">
                              <Icon>home</Icon>
                            </span>
                            <span>
                              <h3 className="text-20 font-medium">Our Location</h3>
                              <h4 className="text-16 font-medium text-grey-600 max-w-360">
                                Office No. 6-7 Level 2, Hermes Palazzo, opp. St Anne's School, Camp,
                                Pune, Maharashtra 411001
                              </h4>
                            </span>
                          </div>
                        </div>
                        <div className="col-span-12 md:col-span-1" />
                        <div className="col-span-12 md:col-span-5 bg-white p-24 rounded-md shadow-lg relative z-10 contact-form-ui">
                          <div className="block mb-24">
                            <InputLabel
                              htmlFor="name"
                              className="text-14 font-semibold mb-6 text-gry-800"
                            >
                              Name*
                            </InputLabel>

                            <Controller
                              control={control}
                              name="name"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder="Enter your name here"
                                  id="name"
                                  error={!!formState.errors.name}
                                  helperText={
                                    formState.errors.name?.message && (
                                      <span className="text-red-900">
                                        {formState.errors.name?.message}
                                      </span>
                                    )
                                  }
                                  variant="outlined"
                                  required
                                  fullWidth
                                  type=""
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      backgroundColor: 'transparent',
                                      height: '42px',
                                      padding: '0 15px',
                                      color: '#000',
                                      '&:-webkit-autofill': {
                                        WebkitTextFillColor: '#000',
                                        caretColor: '#000',
                                      },
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      height: '42px',
                                      border: '1px solid #fff',
                                      backgroundColor: 'transparent',
                                      margin: '1px 0',
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="block mb-24">
                            <InputLabel
                              htmlFor="phoneNumber"
                              className="text-14 font-semibold mb-6 text-gry-800 mt-4"
                            >
                              Mobile Number*
                            </InputLabel>
                            <Controller
                              control={control}
                              name="phoneNumber"
                              render={({ field }) => (
                                <PhoneInput
                                  country={ipBasedData?.isoCode?.toLowerCase()}
                                  value={phoneValue}
                                  enableLongNumbers={17}
                                  onChange={(value, country, event, formattedValue) => {
                                    handlePhoneChange(value, country, event, formattedValue);
                                    field.onChange(value);
                                  }}
                                  enableSearch
                                  variant="filled"
                                  inputStyle={{
                                    width: '100%',
                                    height: '40px',
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                  }}
                                  searchStyle={{ color: 'black' }}
                                />
                              )}
                            />
                          </div>
                          <div className="block">
                            <InputLabel
                              htmlFor="email"
                              className="text-14 font-semibold mb-6 text-gry-800 mt-4"
                            >
                              Email*
                            </InputLabel>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="mb-24"
                                  placeholder="Enter your email here..."
                                  type="email"
                                  error={!!formState.errors.email}
                                  helperText={
                                    <span className="text-red-900">
                                      {formState.errors.email?.message ||
                                        (field.value && !isValidEmail(field.value)
                                          ? 'Invalid email format'
                                          : '')}
                                    </span>
                                  }
                                  variant="outlined"
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      backgroundColor: 'transparent',
                                      height: '42px',
                                      padding: '0 15px',
                                      color: '#000',
                                      '&:-webkit-autofill': {
                                        WebkitTextFillColor: '#000',
                                        caretColor: '#000',
                                      },
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      height: '42px',
                                      border: '1px solid #fff',
                                      backgroundColor: 'transparent',
                                      margin: '1px 0',
                                    },
                                  }}
                                  required
                                  fullWidth
                                />
                              )}
                            />
                          </div>
                          <div className="mb-20">
                            <InputLabel
                              htmlFor="message"
                              className="text-14 font-semibold  mb-6 text-gry-800"
                            >
                              Message*
                            </InputLabel>
                            <div className="mb-2">
                              <Controller
                                control={control}
                                name="message"
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder="Enter your message here..."
                                    id="message"
                                    error={!!formState.errors.message}
                                    helperText={formState.errors.message?.message}
                                    variant="outlined"
                                    InputProps={{
                                      style: {
                                        border: '1px solid #fff',
                                        color: '#000',
                                      },
                                    }}
                                    required
                                    fullWidth
                                    multiline
                                    rows={8}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="w-full rounded-md text-18  font-semibold"
                            disabled={!formState.isValid || refreshIcon || buttonDisabled}
                            onClick={handleSubmit(onSubmit)}
                          >
                            Send Message
                            {refreshIcon && (
                              <CircularProgress
                                size={24}
                                className="text-darkgreen absolute mx-auto"
                              />
                            )}
                          </Button>
                        </div>
                        <img
                          src="/assets/images/icon/background_image.png"
                          alt="bad"
                          className="hidden md:block mx-auto absolute bottom-0 left-2/4"
                        />
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
              </>
              <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                <Link
                  to="blog"
                  role="button"
                  className="hover:text-darkgreen-100 sm:text-white text-black"
                  onClick={() => history.push('/partner')}
                >
                  Partner
                </Link>
              </li>
              {/* <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                <a
                  href={process.env.REACT_APP_PARTNER_INQUIRY}
                  role="button"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  className="hover:text-darkgreen-100 sm:text-white text-black"
                >
                  Partner Inquiry
                </a>
              </li> */}
              <li className="sm:inline-block block lg:px-20 md:px-16 sm:px-8 px-16 sm:pt-0 pt-16">
                <a
                  href={process.env.REACT_APP_PORTAL}
                  role="button"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  className="hover:text-darkgreen hover:bg-white sm:text-white bg-darkgeen text-white border-2 border-white rounded-3xl md:px-28 px-12 py-6 md:py-10"
                >
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withReducer('landingReducer', reducer)(Header);
