import { memo, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Icon,
  Input,
  MenuItem,
  Paper,
  Select,
  SwipeableDrawer,
  TablePagination,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import history from '@history';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import withReducer from 'app/store/withReducer';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import { MuiOtpInput } from 'mui-one-time-password-input';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';
import { useSnackbar } from 'notistack';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import { publicIpv4 } from 'public-ip';
import BrandLogo from '../landing/BrandLogo';
import Testimonial from '../landing/Testimonial';
import { getEncryptedData, getDecryptData } from '../common/common';
import LandingQuery from '../query/landingDetail';
import WelcomeQuery from '../query/welcome';
import reducer from './store';
import {
  getLocation,
  sendOTPOnPhoneNumberVerification,
  verifyPhoneNumberOTP,
  checkAndVerifyPhoneNumber,
  signupV3,
  verifyEmailOTP,
  checkEmail,
  sendOTPOnEmailVerification,
  saveNewsletterSubscription,
  getPackages,
  getReviewPlatforms,
  validateZomatoUrl,
  searchYelpBusiness,
  getCountries,
  getStates,
} from './store/WelcomeSlice';
import AddBusinessDetails from './AddBusinessDetails';

const Welcome = () => {
  const secondsTime = 10;
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
      .required('First Name is required'),
    lastName: yup
      .string()
      .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
      .required('Last Name is required'),
    email: yup.string().email('Invalid email'),
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .min(10, 'Phone number is too short - It should be 10 digits.'),
  });

  const defaultValues = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    }),
    []
  );

  const getTrialFreeData = useSelector(
    ({ welcomeReducer }) =>
      welcomeReducer?.welcome?.sendOTPOnPhoneNumberVerificationData?.data
        ?.sendOTPOnPhoneNumberVerification || ''
  );

  const getPackageResponse = useSelector(
    ({ welcomeReducer }) => welcomeReducer?.welcome?.getPackagesData?.data?.getPackages?.data || []
  );

  const { control, reset, handleSubmit, formState, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();
  const lib = ['places'];
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // PUT GMAP API KEY HERE
  const [loading, setLoading] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [otpRefreshIcon, setOtpRefreshIcon] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [phoneNumberWithoutCode, setPhoneNumberWithoutCode] = useState('');
  const [countryCodeNumber, setCountryCodeNumber] = useState('');
  const [activeType, setActiveType] = useState('free');
  const [isoCode, setIsoCode] = useState('');
  const [ipBasedData, setIpBasedData] = useState(null);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [buttonRedirectLink, setButtonRedirectLink] = useState();
  const [trialSteps, setTrialSteps] = useState(1);
  const [otp, setOtp] = useState('');
  const [emailOtp, setEmailOtp] = useState('');
  const [isEmail, setIsEmail] = useState(false);
  const [resendOtpStatus, setResendOtpStatus] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ lat: 34.0522342, lng: -118.2436849 });
  const [phoneValue, setPhoneValue] = useState();
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState();
  const [addressData, setAddressData] = useState({});
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [trialFormData, setTrialFormData] = useState({});
  const [selectedLocation, setSelectedLocation] = useState();
  const [accessToken, setAccessToken] = useState();
  const [subScribeEmail, setSubscribeEmail] = useState();
  const [isZomato, setIsZomato] = useState(false);
  const [zomatoVerified, setZomatoVerified] = useState(false);
  const [zomatoBusinessName, setZomatoBusinessName] = useState();
  const [zomatoBusinessAddress, setZomatoBusinessAddress] = useState();
  const [zomatoUrl, setZomatoUrl] = useState();
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState({});
  const [isYelp, setIsYelp] = useState(false);
  const [yelpData, setYelpData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [searchBusinessName, setSearchBusinessName] = useState();
  const [countryId, setCountryId] = useState(1);
  const [stateId, setStateId] = useState(0);
  const [zipCode, setZipCode] = useState();
  const [googleData, setGoogleData] = useState({});
  const [countryName, setCountryName] = useState();
  const [stateName, setStateName] = useState();
  const [reviewPlateFormData, setReviewPlateFormData] = useState([]);
  const [yelpBusinessName, setYelpBusinessName] = useState();
  const [yelpBusinessAddress, setYelpBusinessAddress] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [businessDetails, setBusinessDetails] = useState();
  const [countryCode, setCountryCode] = useState();
  const [selectedPackageTypeId, setSelectedPackageTypeId] = useState(
    process.env.REACT_APP_DEFAULT_SIGNUP_PACKAGEID
  );
  const [selectedPackageId, setSelectedPackageId] = useState();
  let prevItem = null;
  const [subscribed, setSubscribed] = useState(false);
  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };
  const handleEmailOtpChange = (newValue) => {
    setEmailOtp(newValue);
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return;
    }
    if (!loading) {
      setLoading(true);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, [loading]);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const fetchYelpData = async (page) => {
      try {
        const payload = {
          query: LandingQuery.searchYelpBusiness,
          variables: {
            location: 'USA',
            search: searchBusinessName,
            pageNumber,
          },
        };
        const result = await dispatch(searchYelpBusiness(payload));
        if (result?.payload?.data?.searchYelpBusiness?.status === 200) {
          setDataAvailable(result?.payload?.data?.searchYelpBusiness?.data?.length === 0 && true);
          setYelpData((prevData) => [...prevData, ...result.payload.data.searchYelpBusiness.data]);
        }
      } catch (error) {
        console.error('Error fetching Yelp data:', error);
      } finally {
        setLoading(false);
      }
    };
    if (!dataAvailable) {
      fetchYelpData();
    }
  }, [dispatch, pageNumber, dataAvailable, searchBusinessName]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
        setResendOtpStatus(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        query: LandingQuery.getReviewPlatforms,
      };
      const result = await dispatch(getReviewPlatforms(payload));
      const excludedPlatforms = ['amazon', 'flipkart', 'quickreviews', 'trustpilot'];
      const data = result?.payload?.data?.getReviewPlatforms?.data;
      const filteredData = data?.filter((platform) => !excludedPlatforms.includes(platform.name));
      const sortData = filteredData?.sort((a, b) => a.id - b.id);
      const updatedData = sortData?.map((platform) => ({
        ...platform,
        isBusinessDetailsAdded: false,
      }));
      setReviewPlateFormData(updatedData);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchCountryData = async () => {
      const payload = {
        query: LandingQuery.getCountries,
      };
      const result = await dispatch(getCountries(payload));
      if (result?.payload?.data?.getCountries?.status === 200) {
        setCountryData(result.payload?.data?.getCountries?.data);
      }
    };
    fetchCountryData();
  }, [dispatch]);

  useEffect(() => {
    const fetchCountryData = async () => {
      const payload = {
        query: LandingQuery.getStates,
        variables: {
          countryId: parseInt(countryId, 10),
        },
      };
      const result = await dispatch(getStates(payload));
      if (result?.payload?.data?.getStates?.status === 200) {
        setStateData(result.payload?.data?.getStates?.data);
      }
    };
    fetchCountryData();
  }, [dispatch, countryId]);
  useEffect(() => {
    if (!ipBasedData) {
      setLoading(true);
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: LandingQuery.getLocation,
          variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
          }
        }
      };
      ipAddressData();
    }
  }, [dispatch, ipBasedData]);
  useEffect(() => {
    if (getTrialFreeData?.status === 200) {
      setTrialSteps(2);
    } else if (getTrialFreeData?.status === 400) {
      enqueueSnackbar(getTrialFreeData?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }, [dispatch, enqueueSnackbar, getTrialFreeData]);

  useEffect(() => {
    let unmounted = false;
    if (ipBasedData) {
      isMobile();
    }
    return () => {
      unmounted = true;
    };
  }, [dispatch, ipBasedData]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        query: LandingQuery.getPackages,
        variables: {
          countryId: ipBasedData?.countryId,
          packageInterval: 'month',
        },
      };
      await dispatch(getPackages(payload));
    };

    fetchData();
  }, [dispatch, ipBasedData?.countryId]);

  useEffect(() => {
    const foundSelectedPackageObject = getPackageResponse.find(
      (item) => item?.packageTypeId === parseInt(selectedPackageTypeId, 10)
    );

    setSelectedPackageId(foundSelectedPackageObject?.id);
  }, [getPackageResponse, selectedPackageTypeId]);

  function isValidEmail(value) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  }

  const validatePhoneNumber = (phoneValues) => {
    const phoneNumberPattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return phoneNumberPattern.test(phoneValues);
  };

  const handlePhoneChange = (value, country, event, formattedValue) => {
    setCountryCode(country?.dialCode);
    const phoneNumberWithoutCodeValue = value.slice(country?.dialCode?.length);
    setPhoneNumberWithoutCode(phoneNumberWithoutCodeValue);
    const isValidPhoneNumber = validatePhoneNumber(phoneNumberWithoutCodeValue);

    setPhoneValue(value);

    setValue('phoneNumber', phoneNumberWithoutCodeValue, {
      shouldDirty: true,
      shouldValidate: true,
      value: isValidPhoneNumber ? phoneNumberWithoutCodeValue : '',
    });

    setCountryCodeNumber(country.dialCode);
    setIsoCode(country.countryCode);
  };

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    setTrialFormData({
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
    });
    try {
      await sendEmailOTP(data?.email);
      await sendMobileOTP();
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
    }
  };
  function getDefaultReviewPlatformId() {
    let defaultReviewPlatformId;

    reviewPlateFormData.forEach((detail) => {
      if (detail.name === 'google' && detail.businessName?.toLowerCase()) {
        defaultReviewPlatformId = 2;
      } else if (detail.name === 'yelp' && detail.businessName?.toLowerCase()) {
        if (defaultReviewPlatformId !== 2) {
          defaultReviewPlatformId = 4;
        }
      } else if (detail.name === 'zomato' && detail.businessName?.toLowerCase()) {
        if (defaultReviewPlatformId !== 2 || defaultReviewPlatformId !== 4) {
          defaultReviewPlatformId = 3;
        }
      }
    });

    return defaultReviewPlatformId;
  }
  async function onSignup() {
    const signupData = getEncryptedData(
      JSON.stringify({
        e: trialFormData?.email.toLocaleLowerCase(),
        firstName: trialFormData?.firstName,
        lastName: trialFormData?.lastName,
        phoneNumber: {
          countryCode: `+${countryCode}`,
          phoneNumber: parseInt(phoneNumberWithoutCode, 10),
          isoCode: ipBasedData?.isoCode,
        },
        countryId: ipBasedData?.countryId,
        accessToken: '',
        userPackageId: parseInt(selectedPackageId, 10) || 1,
        isSocialUser: false,
        timeZone: '',
        isRegisterFromCorporateSite: true,
        ...(businessDetails && {
          businessDetail: [businessDetails],
        }),
      })
    );
    const signupPayload = {
      query: WelcomeQuery.signupV3,
      variables: { data: signupData },
    };
    const signupResult = await dispatch(signupV3(signupPayload));
    if (signupResult?.payload?.signupV3?.status === 200) {
      setAccessToken(signupResult?.payload?.signupV3?.accessToken);
      enqueueSnackbar(signupResult?.payload?.signupV3?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      setTrialSteps(6);
      // history.push(`${process.env.REACT_APP_PORTAL}?token=${signupResult?.payload?.signupV3?.accessToken}`);
    } else {
      enqueueSnackbar(signupResult?.payload?.signupV3?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
    }
  }

  async function submitEmailOtp() {
    if (trialFormData?.email) {
      setOtpRefreshIcon(true);
      const otpValue = parseInt(emailOtp, 10);
      const data = getEncryptedData(`{"email": "${trialFormData?.email}", "otp": ${otpValue}}`);
      const payload = {
        query: WelcomeQuery.verifyOTP,
        variables: { data },
      };
      const result = await dispatch(verifyEmailOTP(payload));
      if (result.payload?.data?.verifyOTP?.status === 200) {
        setEmailOtp('');
        setTrialSteps(4);
        setOtpRefreshIcon(false);
      } else {
        enqueueSnackbar(result.payload?.data?.verifyOTP?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setOtpRefreshIcon(false);
      }
    }
  }
  async function submitOtp() {
    setOtpRefreshIcon(true);
    const otpValue = parseInt(otp, 10);
    if (parseInt(phoneNumberWithoutCode, 10)) {
      const data = getEncryptedData(
        `{"countryCode": "${parseInt(countryCodeNumber, 10)}", "phoneNumber": "${parseInt(
          phoneNumberWithoutCode,
          10
        )}", "otp": ${otpValue}}`
      );
      const payload = {
        query: WelcomeQuery.verifyPhoneNumberOTP,
        variables: { data },
      };
      const result = await dispatch(verifyPhoneNumberOTP(payload));
      const resultData = result.payload?.data?.verifyPhoneNumberOTP;
      if (resultData?.status === 200) {
        if (trialFormData.email) {
          setSeconds(59);
          setTrialSteps(3);
        } else {
          setTrialSteps(4);
        }
        setOtpRefreshIcon(false);
      } else if (resultData?.status !== 200) {
        enqueueSnackbar(resultData?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setOtpRefreshIcon(false);
      }
      setOtp();
      setMinutes(0);
      // return resultData;
    }
  }

  const sendEmailOTP = async (email) => {
    if (email) {
      const emailPayload = {
        query: WelcomeQuery.checkEmail,
        variables: {
          email,
        },
      };
      const result = await dispatch(checkEmail(emailPayload));
      if (
        result?.payload?.checkEmail?.status === 200 &&
        result?.payload?.checkEmail?.available === true
      ) {
        const emailData = {
          query: WelcomeQuery.sendOTPOnEmailVerification,
          variables: { email },
        };
        const res = await dispatch(sendOTPOnEmailVerification(emailData));
        if (result?.payload?.sendOTPOnEmailVerification?.status === 200) {
          enqueueSnackbar(res?.payload?.sendOTPOnEmailVerification?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      } else if (result?.payload?.checkEmail?.available === false) {
        throw new Error('Email already exists');
      }
    }
  };
  const sendMobileOTP = async () => {
    const payloadVerifyPhoneNumber = {
      query: WelcomeQuery.checkAndVerifyPhoneNumber,
      variables: {
        phoneNumber: parseInt(phoneNumberWithoutCode, 10),
      },
    };
    const resultVerify = await dispatch(checkAndVerifyPhoneNumber(payloadVerifyPhoneNumber));
    if (
      resultVerify?.payload?.checkAndVerifyPhoneNumber?.status === 200 &&
      resultVerify?.payload?.checkAndVerifyPhoneNumber?.available === true
    ) {
      setSeconds(59);
      const payload = {
        query: WelcomeQuery.sendOTPOnPhoneNumberVerification,
        variables: {
          phoneNumber: parseInt(phoneNumberWithoutCode, 10),
          countryCode: `+${countryCodeNumber}`,
          isoCode: isoCode.toUpperCase(),
        },
      };
      const response = dispatch(sendOTPOnPhoneNumberVerification(payload));
      if (response?.payload?.sendOTPOnPhoneNumberVerification?.status === 200) {
        enqueueSnackbar(response?.payload?.sendOTPOnPhoneNumberVerification?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } else if (
      resultVerify?.payload?.checkAndVerifyPhoneNumber?.status === 200 &&
      resultVerify?.payload?.checkAndVerifyPhoneNumber?.available === false
    ) {
      throw new Error(resultVerify?.payload?.checkAndVerifyPhoneNumber?.message);
    }
    setRefreshIcon(false);
  };
  const resendEmailOTP = async () => {
    setSeconds(59);
    setEmailOtp('');
    await sendEmailOTP(trialFormData?.email);
  };
  const resendMobileOTP = async () => {
    setSeconds(59);
    setOtp('');
    await sendMobileOTP();
  };

  function isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (regex.test(navigator.userAgent)) {
      const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
      if (navigator.userAgent.match(/iPhone/i) || isSafari) {
        setButtonRedirectLink('https://itunes.apple.com/app/quick-reviews/id6449814972');
      } else {
        setButtonRedirectLink('https://play.google.com/store/apps/details?id=reviewApp.dev');
      }
    } else {
      setButtonRedirectLink('https://app.quickreviews.click/sign-up');
    }
  }

  function redirectAppsLink() {
    history.push(buttonRedirectLink);
  }

  const reverseGeocode = async (location) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${location.lat}&lon=${location.lng}`
      );

      if (response.data) {
        const dataAddress = {
          city: response.data.address.city ?? '',
          country: response.data.address.country ?? '',
          zipCode: response.data.address.postcode ?? '',
          state: response.data.address.state ?? '',
        };

        setAddressData(dataAddress);
        await setSelectedMapFullAddress(response.data.display_name ?? '');
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error in reverse geocoding:', error);
    }
  };

  function onLoad(autocompleteData) {
    setAutocompleteMap(autocompleteData);
  }
  async function onDragChanged(event) {
    if (event) {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setCurrentLocation(newLocation);
      await reverseGeocode(newLocation);
    }
  }

  function onPlaceSearch(event) {
    setSelectedMapFullAddress(event.target.value);
  }
  function handleRedirect() {
    window.open('https://meetings.hubspot.com/quickreviews', '_blank');
  }
  function onPlacesChanged() {
    if (autocompleteMap !== null) {
      const place = autocompleteMap?.getPlace();
      setCurrentLocation({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      });
      const dataAddress = {
        city:
          place?.address_components?.find((component) => component?.types?.includes('locality'))
            ?.long_name || '',
        country:
          place?.address_components?.find((component) => component?.types?.includes('country'))
            ?.long_name || '',
        zipCode:
          place?.address_components?.find((component) => component?.types?.includes('postal_code'))
            ?.long_name || '',
        state:
          place?.address_components?.find((component) =>
            component?.types?.includes('administrative_area_level_1')
          )?.long_name || '',
      };
      setAddressData(dataAddress);
      const formattedAddress = place.formatted_address;
      setSelectedMapFullAddress(formattedAddress);
      if (place?.international_phone_number) {
        const tempPhone = place?.international_phone_number
          ?.split(' ')
          .slice(1)
          .join('')
          .replace(/[^0-9]/g, '');
        setBusinessPhoneNumber({
          phoneNumber: parseInt(tempPhone, 10),
          countryCode: place?.international_phone_number?.split(' ')[0],
          isoCode: place?.international_phone_number?.split(' ')[0] === '+91' ? 'in' : 'us',
        });
      }
      setGoogleData({
        address: formattedAddress,
        businessName: place?.name,
      });
      const data = reviewPlateFormData.map((ele) => {
        if (ele.name === 'google') {
          return {
            ...ele,
            address: formattedAddress,
            businessName: place?.name,
            placeId: place.place_id,
            url: place.url,
            isBusiness: true,
          };
        }
        return ele;
      });
      setReviewPlateFormData(data);
      // setSelectedLocation(data);
      // setOpenDialog(false);
    }
  }

  const handleSubscribe = async () => {
    const email = /\S+@\S+\.\S+/;
    if (!subScribeEmail || !email.test(subScribeEmail)) {
      setIsEmail(true);
    } else {
      setIsEmail(false);
      const payload = {
        query: WelcomeQuery.saveNewsletterSubscription,
        variables: {
          email: subScribeEmail,
        },
      };
      const result = await dispatch(saveNewsletterSubscription(payload));
      if (result?.payload?.saveNewsletterSubscription.status === 200) {
        setSubscribed(true);
        setSubscribeEmail('');
      } else {
        enqueueSnackbar(result?.payload?.saveNewsletterSubscription?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleBusinessLocation = async (data) => {
    if (data.name === 'zomato') {
      setIsZomato(true);
    }
    if (data.name === 'google') {
      setOpenDialog(true);
    }
    if (data.name === 'yelp') {
      setIsYelp(true);
      const payload = {
        query: LandingQuery.searchYelpBusiness,
        variables: {
          location: 'USA',
          search: searchBusinessName,
        },
      };
      const result = await dispatch(searchYelpBusiness(payload));
      if (result?.payload?.data?.searchYelpBusiness?.data) {
        setYelpData(result?.payload?.data?.searchYelpBusiness?.data);
      } else {
        enqueueSnackbar(result?.payload?.data?.searchYelpBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const verifyZomatoLink = async () => {
    setRefreshIcon(true);
    const payload = {
      query: LandingQuery.validateZomatoUrl,
      variables: {
        url: zomatoUrl,
      },
    };
    const result = await dispatch(validateZomatoUrl(payload));
    setRefreshIcon(false);
    if (
      result?.payload?.data?.validateZomatoUrl?.status === 200 &&
      result?.payload?.data?.validateZomatoUrl?.data
    ) {
      const businessData = result?.payload?.data?.validateZomatoUrl?.data;
      setZomatoVerified(true);
      setZomatoBusinessName(businessData?.name);
      setZomatoBusinessAddress(businessData?.address);
      const data = reviewPlateFormData.map((ele) => {
        if (ele.name === 'zomato') {
          return {
            ...ele,
            address: businessData?.address,
            businessName: businessData?.name,
            placeId: businessData?.res_id.toString(),
            url: businessData?.business_url,
            isBusiness: true,
          };
        }
        return ele;
      });
      setReviewPlateFormData(data);
    }
    if (result?.payload?.data?.validateZomatoUrl?.data === null) {
      enqueueSnackbar(result?.payload?.data?.validateZomatoUrl?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleRemoveBusiness = (data) => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.id === data.id) {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
        };
      }
      return ele;
    });
    if (data.name === 'google') {
      setGoogleData();
    }
    if (data.name === 'zomato') {
      setZomatoUrl();
      setZomatoVerified(false);
      setZomatoBusinessName();
    }
    setReviewPlateFormData(removeBusinessInformation);
  };

  const handleYelpAddBusiness = (value) => {
    setYelpBusinessName(value?.name);
    setYelpBusinessAddress(value?.location?.display_address.join(' '));
    const data = reviewPlateFormData.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          ...ele,
          address: value?.location?.display_address.join(' '),
          businessName: value?.name,
          placeId: value?.id,
          url: value?.url,
          isBusiness: true,
        };
      }
      return ele;
    });
    setIsYelp(false);
    setReviewPlateFormData(data);
  };

  const selectCountry = async (country) => {
    setCountryId(country);
    const findCountry = countryData.find((ele) => ele.id === country);
    setCountryName(findCountry.name);
    const payload = {
      query: LandingQuery.searchYelpBusiness,
      variables: {
        location: `${findCountry.name}, ${stateName || ''}, ${zipCode || ''}`,
        search: searchBusinessName,
      },
    };
    const result = await dispatch(searchYelpBusiness(payload));
    if (result?.payload?.data?.searchYelpBusiness?.data) {
      setYelpData(result?.payload?.data?.searchYelpBusiness?.data);
    } else {
      enqueueSnackbar(result?.payload?.data?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const selectState = async (state) => {
    setStateId(parseInt(state, 10) !== 0 ? state : 0);
    const findState = stateData.find((ele) => ele.id === state);
    setStateName(findState?.name || '');
    const payload = {
      query: LandingQuery.searchYelpBusiness,
      variables: {
        location: `${countryName || 'USA'}, ${findState?.name || ''}, ${zipCode || ''}`,
        search: searchBusinessName,
      },
    };
    const result = await dispatch(searchYelpBusiness(payload));
    if (result?.payload?.data?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.payload?.data?.searchYelpBusiness?.data);
    }
  };

  const handleZipCode = async (value) => {
    if (value.length < 7) {
      setZipCode(value);
      const payload = {
        query: LandingQuery.searchYelpBusiness,
        variables: {
          location: `${countryName || 'USA'}, ${stateName || ''}, ${value}`,
          search: searchBusinessName,
        },
      };
      const result = await dispatch(searchYelpBusiness(payload));
      if (result?.payload?.data?.searchYelpBusiness?.status === 200) {
        setYelpData(result?.payload?.data?.searchYelpBusiness?.data);
      }
    }
  };

  const handleSearchBusinessName = async (value) => {
    setSearchBusinessName(value);
    const payload = {
      query: LandingQuery.searchYelpBusiness,
      variables: {
        location: `${countryName || 'USA'}, ${stateName || ''}, ${zipCode || ''}`,
        search: value,
      },
    };
    const result = await dispatch(searchYelpBusiness(payload));
    if (result?.payload?.data?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.payload?.data?.searchYelpBusiness?.data);
    }
  };

  const clearSearch = async () => {
    setSearchBusinessName('');
    const payload = {
      query: LandingQuery.searchYelpBusiness,
      variables: {
        location: `${countryName || 'USA'}, ${stateName || ''}, ${zipCode || ''}`,
        search: '',
      },
    };
    const result = await dispatch(searchYelpBusiness(payload));
    if (result?.payload?.data?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.payload?.data?.searchYelpBusiness?.data);
    }
  };

  const cancelZomato = () => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'zomato') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
    setIsZomato(false);
    setZomatoUrl('');
    setZomatoVerified(false);
    setZomatoBusinessName('');
    setZomatoVerified(false);
  };

  const cancelGoogle = () => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'google') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
    setGoogleData();
    setOpenDialog(false);
  };

  const cancelYelp = () => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
    setIsYelp(false);
    setDataAvailable(false);
    setPageNumber(1);
  };
  return (
    <div className="overflow-hidden">
      {trialSteps === 1 && (
        <div>
          <div>
            <div className="container">
              <div className="md:flex justify-between md:mt-60 mt-28">
                <div className="md:max-w-640 text-center ma:text-left">
                  <Typography
                  // onClick={() => {
                  //   redirectLink();
                  // }}
                  >
                    <img
                      className="react-logo cursor-pointer relative md:-mb-60 mx-auto md:mx-0"
                      width={200}
                      height={100}
                      src="/assets/images/logo/logo_green.png"
                      alt="stockly"
                    />
                  </Typography>
                  <img
                    src="/assets/images/icon/welcome-graph.png"
                    alt="bad"
                    className="mx-auto md:max-w-400 lg:max-w-480 md:inline-block hidden"
                  />
                  <h2 className="lg:text-44 text-28 font-semibold md:mb-24 mb-0 md:mt-44 mt-20">
                    Grow your business with{' '}
                    <span className="md:block text-darkgreen">Quick Reviews</span>
                  </h2>
                  {/* <Typography className="text-18 font-medium">
                    Quick Reviews empower businesses to enhance their online reputation, build
                    customer loyalty and attract new customers.
                  </Typography> */}
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    className="rounded-full border-1 border-solid border-darkgreen text-18 mt-10 px-36 hover:bg-white hover:text-darkgreen md:mb-0 mb-20"
                    size="small"
                    onClick={() => handleRedirect()}
                  >
                    Book Demo
                  </Button>
                </div>
                <form
                  name="registerForm"
                  // noValidate
                  className="form md:min-w-384 lg:min-w-512 bg-grey-100 shadow rounded-md p-16 md:p-36"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <span className="block font-medium text-18 text-center mb-20">
                    Start Your Free Trial Today!
                  </span>
                  <div>
                    <div className="block mb-24">
                      <InputLabel htmlFor="name" className="text-14 mb-6 font-medium text-black">
                        First Name*
                      </InputLabel>

                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="First Name"
                            id="firstName"
                            error={!!formState.errors.firstName}
                            helperText={
                              formState.errors.firstName?.message && (
                                <span className="text-red-900">
                                  {formState.errors.firstName?.message}
                                </span>
                              )
                            }
                            variant="outlined"
                            required
                            fullWidth
                            type=""
                            sx={{
                              '& .MuiInputBase-input': {
                                backgroundColor: '#fff',
                                height: '42px',
                                padding: '0 15px',
                              },
                            }}
                            InputProps={{
                              style: {
                                height: '42px',
                                backgroundColor: '#fff',
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="block mb-24">
                      <InputLabel htmlFor="name" className="text-14 mb-6 font-medium text-black">
                        Last Name*
                      </InputLabel>

                      <Controller
                        control={control}
                        name="lastName"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Last Name"
                            id="lastName"
                            error={!!formState.errors.lastName}
                            helperText={
                              formState.errors.lastName?.message && (
                                <span className="text-red-900">
                                  {formState.errors.lastName?.message}
                                </span>
                              )
                            }
                            variant="outlined"
                            required
                            fullWidth
                            type=""
                            sx={{
                              '& .MuiInputBase-input': {
                                backgroundColor: '#fff',
                                height: '42px',
                                padding: '0 15px',
                              },
                            }}
                            InputProps={{
                              style: {
                                height: '42px',
                                backgroundColor: '#fff',
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="block mb-24">
                      <InputLabel
                        htmlFor="phoneNumber"
                        className="text-14 font-medium mb-6 mt-4 text-black"
                      >
                        Your Phone Number*
                      </InputLabel>
                      <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field }) => (
                          <PhoneInput
                            country={ipBasedData?.isoCode?.toLowerCase()}
                            // country="in"
                            value={phoneValue}
                            onChange={(value, country, event, formattedValue) => {
                              handlePhoneChange(value, country, event, formattedValue);
                            }}
                            enableSearch
                            variant="filled"
                            inputStyle={{
                              width: '100%',
                              height: '40px',
                              backgroundColor: '#fff',
                            }}
                          />
                        )}
                      />
                      {formState.errors.phoneNumber && (
                        <div className="text-red-900 text-center mt-2">
                          {formState.errors.phoneNumber.message}
                        </div>
                      )}
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="email"
                        className="text-14 font-medium mb-6 mt-4 z-0 text-black"
                      >
                        Your Email
                      </InputLabel>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            placeholder="Your Email"
                            type="email"
                            error={!!formState.errors.email}
                            helperText={
                              <span className="text-red-900">
                                {formState.errors.email?.message ||
                                  (field.value && !isValidEmail(field.value)
                                    ? 'Invalid email format'
                                    : '')}
                              </span>
                            }
                            variant="outlined"
                            sx={{
                              '& .MuiInputBase-input': {
                                backgroundColor: '#fff',
                                height: '42px',
                                padding: '0 15px',
                              },
                            }}
                            InputProps={{
                              style: {
                                height: '42px',
                                backgroundColor: '#fff',
                                margin: '1px 0',
                              },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className="w-full rounded-full text-18"
                      disabled={!formState.isValid || refreshIcon}
                    >
                      Start Free Trial
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  </div>
                </form>
              </div>
              <div className="bg-grey-100 rounded-md p-44 grid sm:grid-cols-3 grid-cols-1 gap-0 text-center shadow relative mt-28 mb-28 md:mt-96 md:mb-96">
                <div>
                  <img
                    src="/assets/images/icon/auto.png"
                    alt="bad"
                    className="mx-auto max-w-80 mb-28"
                  />
                  <h3 className="text-20 font-semibold mb-12">Autogenerated Reviews</h3>
                  <Typography className="text-16 font-medium sm:max-w-400">
                    Customers can generate reviews based on the attributes they rated, which helps
                    customers to give feedback quickly and you will get detailed feedback from your
                    customers.
                  </Typography>
                </div>
                <div className="sm:border-l sm:border-r sm:border-grey-300 sm:px-20 sm:mx-20 sm:my-0 my-20">
                  <img
                    src="/assets/images/icon/social.png"
                    alt="bad"
                    className="mx-auto max-w-80 mb-28"
                  />
                  <h3 className="text-20 font-semibold mb-12">Social Media Integration</h3>
                  <Typography className="text-16 font-medium sm:max-w-400">
                    Effortlessly share your business reviews as posts on all social media platforms.
                    It helps you enhance your online reputation.
                  </Typography>
                </div>
                <div>
                  <img
                    src="/assets/images/icon/target.png"
                    alt="bad"
                    className="mx-auto max-w-80 mb-28"
                  />
                  <h3 className="text-20 font-semibold mb-12">Target Messaging</h3>
                  <Typography className="text-16 font-medium sm:max-w-400">
                    Businesses can send personalized messages to customers via SMS or email,
                    addressing them by name and tailoring content based on their preferences,
                    purchase history, or past interactions.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row block sm:flex items-center md:-mx-24">
              <div className="md:w-3/5 px-24">
                <img src="/assets/images/icon/analytics.png" alt="bad" className="mx-auto" />
              </div>
              <div className="md:w-2/5 px-0 md:px-24">
                <h3 className="sm:text-32 text-24 sm:mt-0 mt-20 font-semibold sm:max-w-480 sm:mb-28 mb-12">
                  Get Business Insights Through{' '}
                  <span className="text-darkgreen">Business Analytics</span>
                </h3>
                <Typography className="text-18 font-medium max-w-512">
                  Access deep insights about your customers and your business. Understand your
                  strengths and weaknesses. Make informed decisions.
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="rounded-3xl mt-28 px-28 font-semibold border-1 border-solid border-darkgreen text-16 hover:bg-white hover:text-darkgreen"
                  onClick={() => {
                    redirectAppsLink();
                  }}
                >
                  Start Free Trial
                </Button>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row block sm:flex items-center md:-mx-24 md:flex mt-28 md:mt-96">
              <div className="md:w-2/5 px-0 md:px-24">
                <h3 className="sm:text-32 text-24 sm:mt-0 mt-20 font-semibold sm:max-w-480 sm:mb-28 mb-12">
                  Manage <span className="text-darkgreen">Multiple Review Platforms </span>In One
                  Place.
                </h3>
                <Typography className="text-18 font-medium max-w-512">
                  Manage reviews from Google, Yelp, Zomato, and others all in one place. This saves
                  time and streamlines the review management process.
                </Typography>
                <Button
                  role="button"
                  variant="contained"
                  color="secondary"
                  className="rounded-3xl mt-28 px-28 font-semibold border-1 border-solid border-darkgreen text-16 hover:bg-white hover:text-darkgreen"
                  onClick={() => {
                    redirectAppsLink();
                  }}
                >
                  Start Free Trial
                </Button>
              </div>
              <div className="md:w-3/5 px-24">
                <img
                  src="/assets/images/icon/multiple_platform.png"
                  alt="bad"
                  className="mx-auto"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <h3 className="sm:text-32 text-24 font-semibold sm:mb-28 mb-12 text-center mt-28 md:mt-96">
              What Our <span className="text-darkgreen">Customers Say</span>
            </h3>
            <div>
              <Testimonial />
            </div>
          </div>
          <div className="container">
            <h3 className="sm:text-32 text-24 font-semibold sm:mb-28 mb-12 mt-28 md:mt-96 text-center">
              Trusted By The <span className="text-darkgreen">Best Businesses</span>
            </h3>
            <div>
              <BrandLogo />
            </div>
          </div>

          <div className="container">
            <div className="bg-darkgreen rounded-lg">
              <div className="sm:flex gap-48 w-[90%] m-auto mt-28 md:mt-96 py-40 items-center">
                <img
                  className="sm:w-[190px] md:w-[400px]"
                  src="/assets/images/icon/Group36528.png"
                  alt=""
                />
                <div>
                  <h2 className="text-white text-32 font-medium">Subscribe Our Newsletter!</h2>
                  <p className="text-white opacity-[70%] pt-24 pb-28 text-20">
                    Updated with new business trends, New learning, latest feature's and discounted
                    offers for your business.
                  </p>
                  <div className="sm:flex items-center gap-24">
                    <div className="w-full border">
                      <TextField
                        className="w-full"
                        size="small"
                        value={subScribeEmail}
                        onChange={(e) => {
                          setSubscribeEmail(e.target.value);
                          setSubscribed(false);
                        }}
                        placeholder="Your Email ..."
                        sx={{
                          '& .MuiInputBase-input': {
                            backgroundColor: 'transparent',
                            height: '40px',
                            padding: '0 15px',
                            color: '#fff',
                            '&:-webkit-autofill': {
                              WebkitTextFillColor: '#fff',
                              caretColor: '#fff',
                            },
                          },
                        }}
                        InputProps={{
                          style: {
                            height: '42px',
                            border: 'none',
                            backgroundColor: 'transparent',
                            margin: '1px 0',
                          },
                        }}
                      />
                    </div>
                    <Button
                      className="bg-white mt-12 sm:mt-0 text-darkgreen px-80 text-16 py-20 hover:bg-white"
                      type="button"
                      onClick={() => handleSubscribe()}
                    >
                      Subscribe
                    </Button>
                  </div>
                  {isEmail && (
                    <Typography className="text-red font-bold text-16">
                      Please enter valid email!
                    </Typography>
                  )}
                  {subscribed && (
                    <Typography className="text-[#B3C90E] pt-16 text-16 font-medium">
                      You've successfully subscribed to our newsletter
                    </Typography>
                  )}
                </div>
              </div>
            </div>

            <div className="bottom-footer bg-darkgreen py-10 md:py-16 border-t border-t-grey-100 contact sm:mt-72 mt-28">
              <div className="mx-auto container md:container px-10">
                <div className="flex justify-between items-center">
                  <div className="text-center mx-auto text-14 text-white md:text-16">
                    &copy; {new Date().getFullYear()} CloudPeak Technologies Pvt. Ltd. All Rights
                    Reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {trialSteps === 2 && (
        <div className="bg-darkgreen sm:h-screen flex items-center text-center">
          <div className="bg-white sm:w-auto w-full mx-16 mx-auto p-16 md:p-44 pt-20 sm:rounded-md">
            <div>
              <img
                className="react-logo cursor-pointer mb-20"
                width={150}
                height={100}
                src="/assets/images/logo/logo_green.png"
                alt="stockly"
              />
              <LinearProgress className="h-8 rounded-lg" variant="determinate" value={33} />
              <h2 className="text-24 font-bold mb-0 mt-28">Verify Your Phone Number</h2>
              <Typography className="text-16 font-semibold mt-16 mb-44">
                Please enter the 6-digit verification code sent to your phone number ending in XX
                {phoneNumberWithoutCode.slice(-4)}.
              </Typography>
              <MuiOtpInput
                className="max-w-xs mx-auto"
                TextFieldsProps={{
                  size: 'medium',
                  placeholder: '-',
                  type: 'tel',
                }}
                type="number"
                gap={1}
                length={6}
                value={otp}
                onChange={handleOtpChange}
              />

              <Button
                className="px-32 md:px-52 max-w-sm md:text-18 font-semibold disabled:text-black mt-52"
                variant="contained"
                color="secondary"
                disabled={otp?.length !== 6 || otpRefreshIcon}
                onClick={submitOtp}
                aria-label="Request"
                type="button"
              >
                Verify Phone{' '}
                {otpRefreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
              <div className="card">
                <div className="countdown-text flex items-center justify-center text-16 font-medium mt-20">
                  {seconds > 0 ? (
                    <p>The code can be resent in: 00:{seconds}</p>
                  ) : (
                    <p>Didn't receive the code?</p>
                  )}
                  {resendOtpStatus ? (
                    <CircularProgress
                      size={24}
                      className="text-12 md:text-14 ml-10 mt-1 text-darkgreen mx-auto"
                    />
                  ) : (
                    <Button
                      disabled={seconds > 0}
                      className="decoration-1 text-12 md:text-14 font-medium"
                      color="secondary"
                      style={{
                        color: seconds > 0 ? '#DFE3E8' : '#006B5A',
                      }}
                      onClick={resendMobileOTP}
                    >
                      Resend Code
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {trialSteps === 3 && (
        <div className="bg-darkgreen sm:h-screen flex items-center text-center">
          <div className="bg-white sm:w-auto w-full mx-16 mx-auto p-16 md:p-44 pt-20 sm:rounded-md">
            <div>
              <img
                className="react-logo cursor-pointer mb-20"
                width={150}
                height={100}
                src="/assets/images/logo/logo_green.png"
                alt="stockly"
              />
              <LinearProgress className="h-8 rounded-lg" variant="determinate" value={66} />
              <h2 className="text-24 font-bold mb-0 mt-28">Verify Your Email</h2>
              <Typography className="text-16 font-semibold mt-16 mb-44">
                Please enter the 6-digit verification code sent to your mail address ending in
                {` ${trialFormData?.email?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2')}`}.
              </Typography>
              <MuiOtpInput
                className="max-w-xs mx-auto"
                TextFieldsProps={{
                  size: 'medium',
                  placeholder: '-',
                  type: 'tel',
                }}
                type="number"
                gap={1}
                length={6}
                value={emailOtp}
                onChange={handleEmailOtpChange}
              />

              <Button
                className="px-32 md:px-52 max-w-sm md:text-18 font-semibold disabled:text-black mt-52"
                variant="contained"
                color="secondary"
                disabled={emailOtp?.length !== 6 || otpRefreshIcon}
                onClick={submitEmailOtp}
                aria-label="Request"
                type="button"
              >
                Verify Email{' '}
                {otpRefreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
              <div className="card">
                <div className="countdown-text flex items-center justify-center text-16 font-medium mt-20">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      The code can be resent in: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>Didn't receive the code?</p>
                  )}
                  {resendOtpStatus ? (
                    <CircularProgress
                      size={24}
                      className="text-14 ml-10 mt-1 text-darkgreen mx-auto"
                    />
                  ) : (
                    <Button
                      disabled={seconds > 0}
                      className="decoration-1 text-14 font-medium"
                      color="secondary"
                      style={{
                        color: seconds > 0 ? '#DFE3E8' : '#006B5A',
                      }}
                      onClick={resendEmailOTP}
                    >
                      Resend Code
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {trialSteps === 4 && (
        <div className="bg-darkgreen sm:h-screen flex items-center text-center">
          <div
            className="bg-white sm:w-auto w-full mx-16 mx-auto p-16 md:p-44 pt-20 sm:rounded-md h-640 overflow-scroll lg:min-w-640 lg:max-w-640"
            onScroll={handleScroll}
          >
            <AddBusinessDetails
              reviewPlateFormData={reviewPlateFormData}
              setBusinessDetails={setBusinessDetails}
              setTrialSteps={setTrialSteps}
              setReviewPlateFormData={setReviewPlateFormData}
            />
          </div>
          {openDialog && (
            <Dialog
              classes={{
                paper: 'max-w-640 p-24',
              }}
              open={openDialog}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  setOpenDialog(false);
                }
              }}
              fullWidth
              maxWidth="xs"
            >
              <DialogTitle className="p-0 text-center pb-12 border-b-2 border-solid border-darkgreen-100  mb-24">
                <div className="flex items-center justify-between">
                  <h3 className="text-20 font-semibold">Add Business</h3>
                  <Icon
                    onClick={() => {
                      cancelGoogle();
                    }}
                    className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                  >
                    cancel
                  </Icon>
                </div>
              </DialogTitle>
              {/* <DialogContent className="p-0"> */}
              {/* <DialogContentText
                  id="alert-dialog-description"
                  className="text-16 font-medium text-center text-black w-full"
                > */}
              <LoadScript
                id="script-loader"
                googleMapsApiKey={key}
                libraries={lib}
                loadingElement={<div>Loading...</div>}
              >
                <GoogleMap
                  mapContainerStyle={{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                  }}
                  className="custom-height rounded-md"
                  zoom={8}
                  center={currentLocation}
                >
                  <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                    <StandaloneSearchBox zIndex="999">
                      <input
                        type="text"
                        value={selectedMapFullAddress}
                        onChange={(e) => onPlaceSearch(e)}
                        placeholder="Find Your Location"
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `300px`,
                          height: `32px`,
                          padding: `0 12px`,
                          borderRadius: `3px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          margin: '0 auto',
                          top: '14px',
                          left: '0',
                          right: '0',
                          zIndex: '1',
                        }}
                      />
                    </StandaloneSearchBox>
                  </Autocomplete>
                </GoogleMap>
                {googleData?.businessName && (
                  <div className="bg-white rounded-md p-16 absolute bottom-[35px] left-[35px] w-[65%]">
                    <h4 className="font-bold">{googleData?.businessName}</h4>
                    <Typography>{googleData?.address}</Typography>
                    <div className="text-start mt-24">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className="md:text-14 px-24 font-medium rounded-md border hover:bg-darkgreen !border-darkgreen border-solid bg-darkgreen text-white inline-block align-middle"
                        onClick={() => setOpenDialog(false)}
                      >
                        <span>Add Business</span>
                      </Button>
                    </div>
                  </div>
                )}
              </LoadScript>
              {/* </DialogContentText> */}
              {/* </DialogContent> */}
            </Dialog>
          )}
        </div>
      )}
      {trialSteps === 5 && (
        <div className="bg-darkgreen sm:h-screen flex items-center text-center">
          <div className="bg-white sm:w-auto w-full mx-16 mx-auto p-16 md:p-44 pt-20 sm:rounded-md lg:min-w-640">
            <div>
              <img
                className="react-logo cursor-pointer mb-20"
                width={150}
                height={100}
                src="/assets/images/logo/logo_green.png"
                alt="logo"
              />
              <LinearProgress className="h-8 rounded-lg" variant="determinate" value={100} />
              <h2 className="text-24 font-bold mb-0 mt-28  mb-44">
                {' '}
                Select a plan for a free trial
              </h2>
              <FormControl className="block mb-28">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  className="grid grid-cols-1 gap-20 lg:gap-20"
                  value={selectedPackageTypeId}
                  onChange={(event) => setSelectedPackageTypeId(event?.target?.value)}
                >
                  {getPackageResponse
                    .filter((packageValue) => packageValue?.id !== 1)
                    .sort((a, b) => {
                      if (a?.packageIndex < b?.packageIndex) {
                        return -1;
                      }
                      if (a?.packageIndex > b?.packageIndex) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((packageValue, index) => {
                      const previousItem = prevItem;
                      prevItem = packageValue;
                      return (
                        <FormControlLabel
                          key={index}
                          value={packageValue?.packageTypeId}
                          control={
                            <Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />
                          }
                          label={
                            <code className="flex justify-between items-center w-full">
                              <span className="text-16 font-semibold inline-block capitalize select-plan-signup">
                                {packageValue.name}
                              </span>
                              <span>
                                <span
                                  className={`${
                                    packageValue?.offerAmount
                                      ? 'line-through font-medium text-16 md:text-16'
                                      : 'text-16 font-medium inline-block capitalize'
                                  }`}
                                >
                                  <span>{ipBasedData?.currencySymbol}</span>
                                  {packageValue?.amount}
                                </span>
                                {packageValue?.offerAmount && (
                                  <h3 className="my-0 text-16 md:text-16 font-semibold inline-block ml-5 font-semibold text-16 md:text-16">
                                    <span>{ipBasedData?.currencySymbol}</span>
                                    {packageValue.offerAmount}
                                  </h3>
                                )}
                                /Month
                              </span>
                            </code>
                          }
                          className={`rounded-md p-10 border m-0 relative block w-full border-1 border-solid ${
                            parseInt(selectedPackageTypeId, 10) === packageValue?.packageTypeId
                              ? 'bg-[#006B5A] border-[#006B5A] text-white'
                              : 'bg-white border-grey '
                          }`}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </div>
            <Button
              // disabled={}
              variant="contained"
              color="secondary"
              size="small"
              className="md:text-16 px-28 font-medium disabled:text-black mt-60 rounded-full inline-block align-middle"
              onClick={() => {
                onSignup();
              }}
            >
              <span>Create Account</span>
            </Button>
          </div>
        </div>
      )}
      {trialSteps === 6 && (
        <div className="bg-darkgreen sm:h-screen flex items-center text-center">
          <div className="bg-white sm:w-auto w-full mx-16 mx-auto p-16 md:p-44 pt-20 sm:rounded-md lg:min-w-640">
            <div>
              <img
                className="react-logo cursor-pointer mb-20"
                width={150}
                height={100}
                src="/assets/images/logo/logo_green.png"
                alt="logo"
              />
              <LinearProgress variant="determinate" className="h-8 rounded-lg" value={100} />
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-24 font-bold mb-0 mt-28">
                    All Set, Download Quick Reviews App And Login
                  </h1>
                  <Typography className="max-w-640 font-semibold text-18 mt-28 mb-44 mx-auto">
                    Scan the QR code or just click on the download button to download our app and
                    login with your email or phone number that you created here.
                  </Typography>
                  <div className="mx-auto text-center">
                    <div className="block relative">
                      <img
                        src="/assets/images/welcome/AppQRCode.png"
                        className="img-fluid max-w-200 max-h-200 block sm:inline-block align-middle mx-auto"
                        alt="..."
                      />
                      <span className="block sm:inline-block my-20 sm:my-auto sm:mx-28 before:border-t-1 before:border-t-grey-300 sm:before:border-r-1 before:border-solid before:border-r-grey-300 before:left-0 sm:before:left-auto before:right-0 sm:before:right-auto before:mt-12 sm:before:mt-auto  sm:before:h-full before:absolute sm:before:top-0 before:ml-14">
                        <span className="bg-grey-100 w-28 h-28 rounded-full inline-block pt-6 text-12 font-medium relative">
                          OR
                        </span>
                      </span>
                      <div className="block sm:inline-block align-middle">
                        <a
                          href="https://play.google.com/store/apps/details?id=reviewApp.dev"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="/assets/images/welcome/play_store.png"
                            className="img-fluid max-w-288 mb-28 mx-auto"
                            alt="..."
                          />
                        </a>
                        <a
                          href="https://apps.apple.com/us/app/quick-reviews-user-insights/id6449814972"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="/assets/images/welcome/app_store.png"
                            className="img-fluid max-w-288 mx-auto"
                            alt="..."
                          />
                        </a>
                      </div>
                    </div>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      className="md:text-16 px-48 font-medium disabled:text-black mt-60 rounded-full inline-block align-middle"
                      onClick={() => {
                        history.push(
                          `${process.env.REACT_APP_PORTAL}/sign-in-token/${accessToken}`
                        );
                      }}
                    >
                      <span>Next</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openDialog && (
            <Dialog
              classes={{
                paper: 'max-w-640 p-24',
              }}
              open={openDialog}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  setOpenDialog(false);
                }
              }}
              fullWidth
              maxWidth="xs"
            >
              <DialogTitle className="p-0 text-center pb-12 border-b-2 border-solid border-darkgreen-100  mb-24">
                <div className="flex items-center justify-between">
                  <h3 className="text-20 font-semibold">Add Business</h3>
                  <Icon
                    onClick={() => {
                      setOpenDialog(false);
                    }}
                    className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                  >
                    cancel
                  </Icon>
                </div>
              </DialogTitle>
              {/* <DialogContent className="p-0"> */}
              {/* <DialogContentText
                  id="alert-dialog-description"
                  className="text-16 font-medium text-center text-black w-full"
                > */}
              <LoadScript
                id="script-loader"
                googleMapsApiKey={key}
                libraries={lib}
                loadingElement={<div>Loading...</div>}
              >
                <GoogleMap
                  mapContainerStyle={{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                  }}
                  className="custom-height rounded-md"
                  zoom={8}
                  center={currentLocation}
                >
                  <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                    <StandaloneSearchBox zIndex="999">
                      <input
                        type="text"
                        value={selectedMapFullAddress}
                        onChange={(e) => onPlaceSearch(e)}
                        placeholder="Find Your Location"
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `450px`,
                          height: `32px`,
                          padding: `0 12px`,
                          borderRadius: `3px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          margin: '0 auto',
                          top: '14px',
                          left: '0',
                          right: '0',
                          zIndex: '1',
                        }}
                      />
                    </StandaloneSearchBox>
                  </Autocomplete>
                </GoogleMap>
              </LoadScript>
              {/* </DialogContentText> */}
              {/* </DialogContent> */}
            </Dialog>
          )}
        </div>
      )}
    </div>
  );
};

export default withReducer('welcomeReducer', reducer)(Welcome);
